<template>
    <v-card class="" tile>
        <v-card-text>
            <v-text-field v-model="search" :prepend-inner-icon="icons.mdiMagnify" class="search-field" hide-details
                rounded dense outlined clearable>
            </v-text-field>
        </v-card-text>
        <v-card-text>
            <v-data-table :headers="headers" :loading="isloading" loading-text="Loading... Please wait" :items="content"
                :search="search" sort-by="object" class="elevation-1 row-pointer" style="cursor: pointer"
                @click:row="(item) => viewDataTableElement(item)">
                <template v-slot:item.type="{ item }">
                    <div class="d-flex flex-row">
                        <strong>{{ item.request.requestype.libele }}</strong>
                    </div>
                </template>
                <template v-slot:item.ruser="{ item }">
                    <div v-if="inbox === false">
                        <div class="d-flex flex-column">
                            <span>{{ item.recipient }} </span>
                            <span v-if="item.user === '1'"
                                class="mr-auto text-caption rounded-lg subsecondary secondary--text px-2 mt-1">Professionnal</span>
                            <span v-if="item.user === '0'"
                                class="mr-auto text-caption rounded-lg subprimary primary--text px-2 m-1">Enterprise</span>
                        </div>
                    </div>
                    <div v-else>
                        <div class="d-flex flex-column">
                            <span>{{ item.owner }} </span>
                            <span v-if="item.user === '1'"
                                class="mr-auto text-caption rounded-lg subsecondary secondary--text px-2 mt-1">Professionnal</span>
                            <span v-if="item.user === '0'"
                                class="mr-auto text-caption rounded-lg subprimary primary--text px-2 m-1">Enterprise</span>
                            <!-- <span class="text-caption">{{ item.request.enterprise.email }}</span> -->
                        </div>
                    </div>
                </template>
                <template v-slot:item.object="{ item }">
                    <div class="d-flex flex-row">
                        <span>{{ item.request.object }} </span>
                    </div>
                </template>

                <template v-slot:item.status="{ item }">
                    <div class="d-flex flex-row ">
                        <div class="d-flex flex-row align-center primary--text" v-if="item.status === '2'">
                            <v-icon size="13" class="mr-1 primary--text">{{
                                    icons.mdiCheckboxMarkedCircle
                            }}</v-icon>
                            <span class="text-caption primary--text">Validated</span>
                        </div>
                        <div class="d-flex flex-row align-center" v-else-if="item.status === '0'">
                            <v-icon size="13" class="mr-1 error--text">{{
                                    icons.mdiCloseCircle
                            }}</v-icon>
                            <span class="text-caption error--text">Rejected</span>
                        </div>

                        <div class="d-flex flex-row align-center secondary--text" v-else-if="item.status === '1'">
                            <v-icon size="13" class="mr-1 secondary--text">{{
                                    icons.mdiAlertCircle
                            }}</v-icon>
                            <span class="text-caption secondary--text">Ongoing</span>
                        </div>
                    </div>
                </template>
                <template v-slot:item.actions="{ item }">
                    <div class="d-flex flex-row">
                        <v-icon class="mr-2" small @click.stop="viewDataTableElement(item)">
                            {{ icons.mdiEye }}
                        </v-icon>
                    </div>
                </template>
            </v-data-table>
        </v-card-text>

    </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiEye, mdiShareAll, mdiAlertCircle, mdiCloseCircle, mdiCheckboxMarkedCircle } from '@mdi/js'
var md5 = require("md5")


export default {
    data() {
        return {
            load: true
        }
    },
    props: {
        content: {
            type: Array,
            default: () => { },
        },
        headers: {
            type: Array,
            default: () => { },
        },
        dash: {
            type: String,
            default: 'user'
        },
        inbox: {
            type: Boolean,
            default: true
        },
        isloading: {
            type: Boolean,
            default: true,
        }
    },
    beforeMount() {
        this.load = this.isDatasLoading
    },
    methods: {
        show(v) {
            alert(v)
        },
        highlightClickedRow(value) {
            const tr = value.target.parentNode;
            tr.classList.add('primary');
        },
        viewDataTableElement(item) {
            // this.editedIndex = this.desserts.indexOf(item)
            // this.editedItem = Object.assign({}, item)
            // this.dialog = true
            var req = 'RFC'
            if ((item.request.requestype.libele === 'RFP') || (item.request.requestype.libele === 'REOI')) {
                req = 'RFP'
            }
            this.$store.commit("setViewrequest", {
                inbox: this.inbox,
                owner: this.dash,
                type: req,
                id: item.id
            });
            if (this.dash === 'user') {
                this.$router.push({
                    name: 'u.request.detail',
                    params: {
                        f_id: md5(item.id + 'l')
                    }
                })
            }
            else {
                this.$router.push({
                    name: 'c.request.detail',
                    params: {
                        company: this.$store.getters.getCompany.name, ids: item.id, f_id: md5(item.id + 'l')
                    }
                })
            }
        },
        deleteItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.desserts.splice(this.editedIndex, 1)
            this.closeDelete()
        },
        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.desserts[this.editedIndex], this.editedItem)
            } else {
                this.desserts.push(this.editedItem)
            }
            this.close()
        },
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
        },
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
    setup(props) {
        const icons = { mdiMagnify, mdiEye, mdiShareAll, mdiAlertCircle, mdiCloseCircle, mdiCheckboxMarkedCircle }
        let search = ref('')
        return {
            search, icons,
        }
    },
}
</script>

<style lang="scss" scoped>
//.row-pointer>.v-data-table__wrapper>table>tbody>tr:hover {
//   cursor: pointer;
//}

.row-pointer>>>tbody tr :hover {
    cursor: pointer;
}
</style>